import React from "react"
import Layout from "../components/layouts/Layout.js"
import * as styles from "../stylesheets/pages/nola.module.scss"
import { graphql, useStaticQuery, Link } from "gatsby"
import { RichText } from "prismic-reactjs"
import GatsbyLink from "../components/GatsbyLink"
import LazyLoad from "react-lazyload"

const Nola = () => {
  const data = useStaticQuery(graphql`
    query {
      prismicNola {
        data {
          content {
            richText
          }
          title {
            richText
          }
          banner_image {
            url
            alt
          }
          top_icon {
            alt
            url
          }
          remote_support_description {
            richText
          }
          contact_text {
            richText
          }
        }
      }
    }
  `).prismicNola.data

  console.log(data)

  return (
    <Layout>
      <div className={styles.container}>
        <div className={styles.imageContainer}>
          <img
            src={data.banner_image.url}
            //src={banner}
            alt={data.banner_image.alt}
            className={styles.mainImage}
          />
          <h1 className={styles.title}>New Orleans TechCollective</h1>
        </div>
        <div className={styles.contentContainer}>
          <h2 className={styles.subTitleTop}>
            TechCollective + C4 Tech and Design
          </h2>

          {/* <h3 className={styles.subTitle}>
            We are excited to announce that C4 Tech and Design is merging with
            TechCollective!{" "}
          </h3> */}
          <div>
            <RichText
              render={data.content.richText}
              serializeHyperlink={GatsbyLink}
            />
          </div>
          <img
            src={data.top_icon.url}
            alt={data.top_icon.alt}
            className={styles.iconImage}
          />
          <Link to="/free-consultation">
            <button className={styles.button}>LET'S TALK</button>
          </Link>

          <div className={styles.divider}></div>
          <h2 className={styles.subTitle}>Remote Support</h2>

          <div className={styles.remoteContainer}>
            <div className={styles.remoteText}>
              <RichText
                render={data.remote_support_description.richText}
                serializeHyperlink={GatsbyLink}
              />
            </div>

            <a href="http://techcollective.screenconnect.com">
              <button className={styles.button}>REMOTE SESSION</button>
            </a>
          </div>
          <div className={styles.divider}></div>
          <h2 className={styles.subTitle}>Contact Us</h2>
          <div className={styles.contactContainer}>
            <div className={styles.contactText}>
              <RichText
                render={data.contact_text.richText}
                serializeHyperlink={GatsbyLink}
              />
            </div>
            <LazyLoad>
              <iframe
                className={styles.map}
                src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d110629.22985345883!2d-90.15816590212931!3d29.945977321874796!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sus!4v1679580419391!5m2!1sen!2sus"
                title={"New Orleans TechCollective Map"}
              ></iframe>
            </LazyLoad>
          </div>
        </div>
      </div>
    </Layout>
  )
}
export default Nola
